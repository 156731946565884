<template>
    <div>
        <v-progress-linear indeterminate absolute color="secondary" :active="carregando" />
        <v-simple-table v-if="alunos.total > 0">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th style="width: 20px;"></th>
                        <th>Aluno</th>
                        <th>Responsável</th>
                        <th style="width: 30px;" v-if="id_turma"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(r, i) in alunos.lista" :key="i">
                        <td class="pr-0">
                            <v-avatar size="40" v-if="r.imagem_perfil">
                                <img v-auth-image="`${apiUrl}usuario/${r.id_usuario}/imagem/thumb.jpg`"/>
                            </v-avatar>
                            <v-avatar size="40" v-else :color="corAvatar(r.nome_completo)">
                                <span class="white--text headline">{{r.nome_completo.substr(0, 1)}}</span>
                            </v-avatar>
                        </td>
                        <td :key="r.id_aluno">
                            <router-link exact color="primary" v-if="permissao('aluno_abrir')" :to="`/aluno/gerenciar/${r.id_aluno}`"><strong>{{r.nome_completo}}</strong></router-link>
                            <strong v-else>{{r.nome_completo}}</strong><br />
                            <small><strong>ID:</strong> {{r.id_aluno}}</small>
                        </td>
                        <td>
                            <router-link exact color="primary" v-if="permissao('responsavel_abrir')" :to="`/responsavel/gerenciar/${r.id_responsavel}`"><strong>{{r.responsavel_razao_social_nome_completo}}</strong></router-link>
                            <strong v-else>{{r.responsavel_razao_social_nome_completo}}</strong>
                        </td>
                        <td v-if="id_turma"><v-btn @click="removerAlunoTurma(r.id_aluno)" icon><v-icon>mdi-delete</v-icon></v-btn></td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-alert v-else type="info" color="blue-grey" text class="mb-0">Registros não encontrados</v-alert>
        <v-divider/>
        <Paginacao :disabled="carregando" @paginar="paginar" :total="alunos.total" />
    </div>
</template>

<script>

import Paginacao from '@/Views/Estrutura/Paginacao'
import axios from "axios"
import {mapState} from "vuex"

export default {
    name: "AlunoListar",
    props : ['filtro', 'meu', 'id_turma'],
    components: {Paginacao},
    data() {
        return {
            carregando : false,
            alunos : {
                lista : [],
                total : 0
            }
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl', 'paginationLimit'])
    },
    methods : {
        removerAlunoTurma(id_aluno) {
            this.carregando = true
            return axios.post(`${this.baseUrl}turma/aluno_excluir`, {id_turma: this.id_turma, id_aluno : id_aluno}).then((res) => {
                if (res.data) {
                    this.paginar(0)
                } else {
                    alert("Erro ao excluir registro")
                }
                this.carregando = false
            })
        },
        paginar(pagina) {
            this.carregando = true
            return axios.post(`${this.apiUrl}aluno/listar`, {
                pagina,
                limit          : this.paginationLimit,
                offset         : this.paginationLimit * pagina,
                busca          : this.filtro.busca || null,
                id_franquia    : this.filtro.id_franquia || null,
                id_responsavel : this.filtro.id_responsavel || null,
                id_turma       : this.filtro.id_turma || null,
                meu            : this.meu || false
            }).then( (res) => {
                this.alunos = res.data
                this.carregando = false
            })
        }
    },
    watch : {
        'filtro.busca' : function () {
            this.paginar(0)
        },
        'filtro.id_franquia' : function () {
            this.paginar(0)
        },
        'filtro.id_responsavel' : function () {
            this.paginar(0)
        },
        'filtro.id_turma' : function () {
            this.paginar(0)
        },
        'filtro.meu' : function () {
            this.paginar(0)
        },
        'id_turma' : function () {
            this.paginar(0)
        }
    },
    activated() {
        this.paginar(0)
    },
    created() {
        this.paginar(0)
    }
}
</script>

<style scoped>

</style>
