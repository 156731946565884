<template>
    <v-pagination :disabled="disabled" v-if="tamanho > 1" v-model="pagina" :length="tamanho" :total-visible="5"  />
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: "Paginacao",
        props : ['total', 'gerenciar', 'disabled'],
        data() {
            return {
                selecionado : 1,
                pagina : 1
            }
        },
        computed : {
            ...mapState(['paginationLimit', 'paginationLimitGerenciar']),
            tamanho() {
                return Math.ceil( ( this.total / ( this.gerenciar != null ? this.paginationLimitGerenciar : this.paginationLimit ) ) )
            }
        },
        watch : {
            pagina(p) {

                this.selecionado = p
                this.$emit('paginar', (p - 1))
            }
        }
    }
</script>

<style scoped>

</style>
