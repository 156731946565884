<template>
    <v-row>
        <v-col cols="12">
            <v-btn :to="`/turma/aluno_incluir/${id_turma}`" text><v-icon left>mdi-plus</v-icon>Incluir aluno</v-btn>
            <v-btn :to="`/aula/cadastrar/${id_turma}`" text><v-icon left>mdi-lead-pencil</v-icon>Nova aula</v-btn>
        </v-col>
        <v-col cols="12" class="pt-0">
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                    <v-spacer />
                    <v-btn v-if="permissao('turma_editar')" exact :to="`/turma/alterar/${id_turma}`" icon class="py-4"><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn v-if="permissao('turma_excluir')" exact @click="excluir" icon class="py-4"><v-icon>mdi-delete</v-icon></v-btn>
                    <v-btn to="/turma" exact icon><v-icon>mdi-arrow-left</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <p class="text--primary mb-2"><strong>Turma:</strong> {{turma.turma}}</p>
                            <p class="text--primary mb-2"><strong>ID:</strong> {{this.id_turma}}</p>
                            <p class="text--primary mb-2">
                                <strong>Professor responsável: </strong>
                                <router-link v-if="permissao('usuario_abrir')" :to="`/usuario/gerenciar/${turma.id_usuario_professor_responsavel}`">{{turma.usuario_professor_responsavel}}</router-link>
                                <template v-else>{{turma.usuario_professor_responsavel}}</template>
                            </p>
                            <p class="text--primary mb-2">
                                <strong>Franquia: </strong>
                                <router-link v-if="permissao('franquia_abrir')" :to="`/franquia/gerenciar/${turma.id_franquia}`">{{turma.franquia}}</router-link>
                                <template v-else>{{turma.franquia}}</template>
                            </p>
                            <p class="text--primary mb-2"><strong>Cadastrada em:</strong> {{dataPt(turma.datahora_cadastro)}}</p>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <p class="text--primary mb-2"><strong>Início das aulas:</strong> {{dataPt(turma.dt_inicio)}}</p>
                            <p class="text--primary mb-2"><strong>Conclusão das aulas:</strong> {{dataPt(turma.dt_fim)}}</p>
                            <p class="text--primary mb-2"><strong>Curso:</strong> {{turma.curso}}</p>
                            <p class="text--primary mb-2"><strong>Total de alunos:</strong> {{turma.aluno_total}}</p>
                            <p class="text--primary mb-2"><strong>Status:</strong> {{turma.status}}</p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12">
            <v-tabs v-model="tab" class="elevation-3" show-arrows dark background-color="primary">
                <v-tab>Aulas</v-tab>
                <v-tab>Alunos</v-tab>
                <!--<v-tab>* Vocabulário</v-tab>-->
            </v-tabs>
            <v-tabs-items v-model="tab" class="elevation-3">
                <v-tab-item>
                    <v-card>
                        <v-toolbar color="primary" dark flat height="auto">
                            <v-btn fab text small @click="$refs.calendario.anterior()"><v-icon small>mdi-chevron-left</v-icon></v-btn>
                            <v-btn fab text small class="mr-5" @click="$refs.calendario.proximo()"><v-icon small>mdi-chevron-right</v-icon></v-btn>
                            <v-toolbar-title class="py-2">{{data}}</v-toolbar-title>
                        </v-toolbar>
                        <v-divider/>
                        <v-card-text><AulaListar @dataCalendario="atualizarData" ref="calendario" :filtro="{id_turma : parseInt(this.id_turma), id_franquia : parseInt(this.turma.id_franquia)}"/></v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <AlunoListar :id_turma="id_turma" :filtro="{id_turma : parseInt(id_turma)}"/>
                </v-tab-item>
                <!--<v-tab-item>
                    <v-divider/>
                    <v-card-text>/* Listar palavras buscadas da disciplina em questão */</v-card-text>
                </v-tab-item>-->
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>

import AulaListar from "@/Views/Aula/Component/AulaListar"
import AlunoListar from "@/Views/Aluno/Component/AlunoListar"
import {mapState} from "vuex"
import axios from "axios"

export default {
    name: "TurmaGerenciar",
    components: { AlunoListar, AulaListar },
    props : ['id_turma','id_usuario'],
    data() {
        return {
            data : '',
            tab : null,
            turma : {},
            registro : {}
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl'])
    },
    methods : {
        atualizarData(d) {
            let meses = [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ]
            let data = d.split('-')
            this.data = `${meses[parseInt(data[1])-1]} de ${data[0]}`
        },
        get() {
            return axios.post(`${this.apiUrl}turma/get`, {id_turma : parseInt(this.id_turma)}).then( (res) => {
                this.turma = res.data
            })
        },
        excluir() {
            let c = confirm("Essa ação não pode ser desfeita, tem certeza que deseja excluir?")
            if (c) {
                return axios.post(`${this.baseUrl}turma/excluir`, {id_turma: this.id_turma}).then((res) => {
                    if (res.data) {
                        this.$router.push({path: '/turma'})
                    } else {
                        alert("Erro ao excluir registro")
                    }
                })
            }
        }
    },
    activated() {
        this.get()
    }
}
</script>

<style scoped>

</style>
